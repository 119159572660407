import { createApp } from 'vue';

import { configure, debug, getConfig, hasConfigured } from './config';
import { version } from '../package.json';

import AuthProvider from './providers/auth.provider';
import CustomerProvider from './providers/customer.provider';
import MemberProvider from './providers/member.provider';
import SearchProvider from './providers/search.provider';
import SubscriptionProvider from './providers/subscription.provider';
import EventProvider from './providers/event.provider';
import GalleriesProvider from './providers/galleries.provider';
import InstructorsProvider from './providers/instructors.provider';
import CouponCodesProvider from './providers/coupon.codes.provider';
import DashboardProvider from './providers/dashboard.provider';
import SectionsProvider from './providers/sections.provider';
import CollectionsProvider from './providers/collections.provider';

import '@/assets/css/reset.css';
import '@/assets/css/variables.css';
import '@/assets/css/global.css';

(function(w, f) {
  var l = f + 'Queue';
  w[l] = w[l] || [];
  w[f] = w[f] || function() {
    w[l].push(arguments);
  };
})(window, 'koc');

const components = {
  'account-payment-profiles-view': () => import(/* webpackChunkName: "account-payment-profiles-view" */ './components/account/views/payment-profiles/payment-profiles.vue'),

  'auth-login-register-view': () => import(/* webpackChunkName: "auth-login-register-view" */ './components/auth/views/login-register/login-register.vue'),

  'purchase-bundle-view': () => import(/* webpackChunkName: "purchase-bundle-view" */ './components/purchase/views/simplified/bundle/bundle.vue'),
  'purchase-course-view': () => import(/* webpackChunkName: "purchase-course-view" */ './components/purchase/views/simplified/course/course.vue'),
  'purchase-cyber-view': () => import(/* webpackChunkName: "purchase-cyber-view" */ './components/purchase/views/simplified/cyber/cyber.vue'),
  'purchase-gift-view': () => import(/* webpackChunkName: "purchase-gift-view" */ './components/purchase/views/simplified/gift/gift.vue'),
  'purchase-redeem-view': () => import(/* webpackChunkName: "purchase-redeem-view" */ './components/purchase/views/simplified/redeem/redeem.vue'),
  'purchase-redeem-single-view': () => import(/* webpackChunkName: "purchase-redeem-single-view" */ './components/purchase/views/simplified/redeem/single.vue'),
  'purchase-subscription-view': () => import(/* webpackChunkName: "purchase-subscription-view" */ './components/purchase/views/simplified/subscription/subscription.vue'),

  'purchase-event-view': () => import(/* webpackChunkName: "purchase-event-view" */ './components/purchase/views/event/event.vue'),
  'purchase-event-psw-thank-you-view': () => import(/* webpackChunkName: "purchase-event-psw-thank-you-view" */ './components/purchase/views/event/thank-you-psw/thank-you-psw.vue'),
  'purchase-conference-live-thank-you-view': () => import(/* webpackChunkName: "purchase-conference-live-thank-you-view" */ './components/purchase/views/event/thank-you-conference-live/thank-you-conference-live.vue'),

  'subscription-change-plan-view': () => import(/* webpackChunkName: "subscription-change-plan-view" */ './components/subscription/views/simplified/change-plan/change-plan.vue'),
  'subscription-migrate-view': () => import(/* webpackChunkName: "subscription-migrate-view" */ './components/subscription/views/simplified/migrate/migrate.vue'),
  'subscription-pay-view': () => import(/* webpackChunkName: "subscription-pay-view" */ './components/subscription/views/simplified/pay/pay.vue'),
  'subscription-renew-view': () => import(/* webpackChunkName: "subscription-renew-view" */ './components/subscription/views/simplified/renew/renew.vue'),

  'guru-awards-view': () => import(/* webpackChunkName: "guru-awards-view" */ './components/galleries/views/guru-awards/guru-awards.vue'),
  'galleries-display-view': () => import(/* webpackChunkName: "guru-awards-view" */ './components/galleries/views/galleries-display/galleries-display.vue'),
  'member-galleries-submit-display-view': () => import(/* webpackChunkName: "member-galleries-submit-display-view" */ './components/galleries/views/member-galleries-submit-display/member-galleries-submit-display.vue'),
  'guru-awards-gallery-view': () => import(/* webpackChunkName: "guru-awards-gallery-view" */ './components/galleries/views/guru-awards-gallery/guru-awards-gallery.vue'),
  'event-contest-gallery-submit-display-view': () => import(/* webpackChunkName: "event-contest-gallery-submit-display-view" */ './components/galleries/views/event-contest-gallery-submit-display/event-contest-gallery-submit-display.vue'),

  'content-search-view': () => import(/* webpackChunkName: "content-search-view" */ './components/content/views/search/search.vue'),
  'content-options-view': () => import(/* webpackChunkName: "content-options-view" */ './components/content/views/options.vue'),

  'content-collection-view': () => import(/* webpackChunkName: "content-collection-view" */ './components/content/views/collection/collection.vue'),

  'events-purchase-view': () => import(/* webpackChunkName: "events-purchase-view" */ './components/events/views/kol-events-purchase/kol-events-purchase.vue'),

  'event-price-box-view': () => import(/* webpackChunkName: "event-price-box-view" */ './components/events/views/event-price-box-view/event-price-box-view.vue'),
  'events-instructor-list-view': () => import(/* webpackChunkName: "events-instructor-list-view" */ './components/events/views/kol-instructor-list/kol-instructor-list.vue'),
  'events-showfloor-list-view': () => import(/* webpackChunkName: "events-showfloor-list-view" */ './components/events/views/kol-showfloor-list/kol-showfloor-list.vue'),
  'events-sponsor-logos-view': () => import(/* webpackChunkName: "events-sponsor-logos-view" */ './components/events/views/kol-sponsor-logos/kol-sponsor-logos.vue'),
  'events-sponsor-deal-view': () => import(/* webpackChunkName: "events-sponsor-deal-view" */ './components/events/views/kol-sponsor-deal/kol-sponsor-deal.vue'),
  'events-account-page-view': () => import(/* webpackChunkName: "events-account-page-view" */ './components/events/views/kol-account-page/kol-account-page.vue'),
  'email-notification-settings-view': () => import(/* webpackChunkName: "email-notification-settings-view" */ './components/email-notification-settings/views/email-notification-settings.vue'),
  'coupon-codes-redemption-view': () => import(/* webpackChunkName: "events-sponsor-deal-view" */ './components/coupon-codes/views/redemption/redemption.vue'),
  'course-sections-view': () => import(/* webpackChunkName: "course-sections-view" */ './components/content/views/course-sections/course-sections.vue'),
  'member-account-page-view': () => import(/* webpackChunkName: "member-account-page-view" */ './components/account/views/member-account-page/member-account-page.vue'),
  'member-dashboard-view': () => import(/* webpackChunkName: "dashboard-view" */ './components/member-dashboard/member-dashboard.vue'),
  'default-collection-view': () => import(/* webpackChunkName: "default-collection-view" */ './components/content/views/default-collection/default-collection.vue'),
  'course-content-view': () => import(/* webpackChunkName: "course-content-view" */ './components/content/views/course-content/course-content.vue'),
  'course-instructors-view': () => import(/* webpackChunkName: "course-instructors-view" */ './components/content/views/course-instructors/course-instructors.vue'),
  'member-challenge-view': () => import(/* webpackChunkName: "member-challenge-view" */ './components/content/views/member-challenge/member-challenge.vue'),
  'price-offer-view': () => import(/* webpackChunkName: "price-offer-view" */ './components/content/views/price-offer/price-offer.vue'),
  'price-toggle-single-card-view': () => import(/* webpackChunkName: "price-toggle-single-card-view" */ './components/content/views/price-toggle-single-card/price-toggle-single-card.vue'),
  'price-toggle-dual-cards-no-modal-view': () => import(/* webpackChunkName: "price-toggle-dual-cards-no-modal-view" */ './components/content/views/price-toggle-dual-cards-no-modal/price-toggle-dual-cards-no-modal.vue'),
  'price-toggle-dual-cards-modal-view': () => import(/* webpackChunkName: "price-toggle-dual-cards-modal-view" */ './components/content/views/price-toggle-dual-cards-modal/price-toggle-dual-cards-modal.vue'),
  'on-one-view': () => import(/* webpackChunkName: "on-one-view" */ './components/content/views/on-one/on-one.vue'),
  'price-offer-single-card-view': () => import(/* webpackChunkName: "price-offer-single-card-view" */ './components/content/views/price-offer-single-card/price-offer-single-card.vue'),
  'deals-section-view': () => import(/* webpackChunkName: "deals-section-view" */ './components/content/views/deals-section/deals-section.vue'),
  'event-schedule-track-view': () => import(/* webpackChunkName: "event-schedule-track-view" */ './components/events/views/event-schedule-track/event-schedule-track.vue'),
  'event-schedule-tabs-view': () => import(/* webpackChunkName: "event-schedule-tabs-view" */ './components/events/views/event-schedule-tabs/event-schedule-tabs.vue'),
  'course-product-offer': () => import(/* webpackChunkName: "course-product-offer" */ './components/content/views/course-product-offer/course-product-offer.vue'),
  'course-page-view': () => import(/* webpackChunkName: "course-page-view" */ './components/content/views/course-page/course-page.vue'),
  'reset-password-view': () => import(/* webpackChunkName: "reset-password-view" */ './components/auth/views/reset-password/reset-password.vue'),
  'login-register-redirect-view': () => import(/* webpackChunkName: "login-register-redirect-view" */ './components/auth/views/login-register-redirect/login-register-redirect.vue'),
  'event-livestream-view': () => import(/* webpackChunkName: "event-livestream-view" */ './components/events/views/event-livestream/event-livestream.vue'),
  'event-replay-sections-view': () => import(/* webpackChunkName: "event-replay-sections-view" */ './components/events/views/event-replay-sections/event-replay-sections.vue'),
  'event-live-now-cards': () => import(/* webpackChunkName: "event-live-now-cards" */ './components/events/views/event-live-now-cards/event-live-now-cards.vue'),
  'navbar-view': () => import(/* webpackChunkName: "navbar-view" */ './components/content/views/navbar/navbar.vue'),
  'purchase-thank-you-summary-view': () => import(/* webpackChunkName: "purchase-thank-you-summary-view" */ './components/purchase/views/thank-you-summary/thank-you-summary.vue'),
  'magazine-collection-view': () => import(/* webpackChunkName: "magazine-collection-view" */ './components/content/views/magazine-collection/magazine-collection.vue'),
  'learning-path-single-view': () => import(/* webpackChunkName: "learning-path-single-view" */ './components/content/views/track-page/track-page.vue'),
  'tutorial-page-view': () => import(/* webpackChunkName: "tutorial-page-view" */ './components/content/views/tutorial-page/tutorial-page.vue'),
  'webcast-page-view': () => import(/* webpackChunkName: "webcast-page-view" */ './components/content/views/webcast-page/webcast-page.vue'),
  'jwplayer-chat-view': () => import(/* webpackChunkName: "jwplayer-chat-view" */ './components/content/blocks/jwplayer-chat/jwplayer-chat.vue'),
  'course-trailers-view': () => import(/* webpackChunkName: "course-trailers-view" */ './components/content/views/course-trailers/course-trailers.vue'),
};

const loadComponent = async ({
  el,
  name,
  props,
  on,
} = {}) => {
  if (!hasConfigured()) {
    throw new Error(
      'Components have not been configured. Run `configure()` before loading components.',
    );
  }
  if (!components[name]) {
    throw new Error(`No Component found for '${name}'`);
  }

  const { default: Component } = await components[name]();

  if (props.require) {
    const { require } = props;

    require.forEach((include) => {
      const script = document.createElement('script');
      script.setAttribute('src', include);
      script.async = true;
      document.head.appendChild(script);
    });
  }

  const appConfig = getConfig();
  const componentConfig = props.config;
  let config = { ...appConfig, ...componentConfig };

  if (config.analytics) {
    if (!window.ga || !window.dataLayer || !window.fbq) {
      const url = `${config.api.uri}/${config.api.version}/checkout/analytics`;
      const response = await fetch(url);
      const data = await response.json();

      config.analytics = data.data.analytics || null;

      if (config.analytics.ga) {
        includeGA(config.analytics.ga, config);
      }
      if (config.analytics.gtm) {
        includeGTM(config.analytics.gtm, config);
      }
      if (config.analytics.fbq) {
        includeFBQ(config.analytics.fbq, config);
      }
    }
  }

  delete props.config;

  const app = createApp(Component, props);

  app.config.compilerOptions.isCustomElement = tag => tag.startsWith('ion-');

  app.provide('config', config);
  app.provide('props', props);
  app.provide('authProvider', AuthProvider);
  app.provide('customerProvider', CustomerProvider);
  app.provide('memberProvider', MemberProvider);
  app.provide('searchProvider', SearchProvider);
  app.provide('subscriptionProvider', SubscriptionProvider);
  app.provide('eventProvider', EventProvider);
  app.provide('galleriesProvider', GalleriesProvider);
  app.provide('instructorsProvider', InstructorsProvider);
  app.provide('couponCodesProvider', CouponCodesProvider);
  app.provide('dashboardProvider', DashboardProvider);
  app.provide('sectionsProvider', SectionsProvider);
  app.provide('collectionsProvider', CollectionsProvider);

  app.mount(el);

  if ('ResizeObserver' in self) {
    const ro = new ResizeObserver(function(entries) {
      const defaultBreakpoints = {
        'koc-small': 400,
        'koc-medium': 800,
        'koc-large': 1200,
      };

      entries.forEach(function(entry) {
        const breakpoints = entry.target.dataset.breakpoints ?
          JSON.parse(entry.target.dataset.breakpoints) :
          defaultBreakpoints;

        Object.keys(breakpoints).forEach(function(breakpoint) {
          const minWidth = breakpoints[breakpoint];
          if (entry.contentRect.width >= minWidth) {
            entry.target.classList.add(breakpoint);
          } else {
            entry.target.classList.remove(breakpoint);
          }
        });
      });
    });

    const elements = document.querySelectorAll('[data-observe-resize]');
    for (let element, i = 0; element = elements[i]; i++) {
      ro.observe(element);
    }

    const containers = document.querySelectorAll('[data-observe-theme]');

    const toggleTheme = (theme) => {
      for (let element, i = 0; element = containers[i]; i++) {
        element.classList.remove('koc-dark-theme');
        element.classList.remove('koc-light-theme');
        element.classList.add(`koc-${theme}-theme`);
      }
    };
    if (config.debug) {
      console.log('config', config);
    }
    if (config.theme) {
      toggleTheme(config.theme);
    }
    document.addEventListener('koc-theme-toggle', (event) => {
      toggleTheme(event.detail.theme);
    });
  }

  if (debug()) {
    console.info(`Component '${name}' mounted`, {
      el,
      props,
      on,
    });
  }

  const ioniconsESM = document.createElement('script');
  ioniconsESM.setAttribute('id', `ionicons-esm`);
  ioniconsESM.setAttribute('type', `module`);
  ioniconsESM.src = `https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.esm.js`;
  document.head.appendChild(ioniconsESM);

  const ionicons = document.createElement('script');
  ionicons.setAttribute('id', `ionicons-esm`);
  ionicons.setAttribute('nomodule', '');
  ionicons.src = `https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.js`;
  document.head.appendChild(ionicons);

};

const includeGA = (identifier, config) => {
  if (!window.ga || window.ga === undefined) {
    if (config.debug) {
      console.log('lib.js:include ga script', {
        identifier,
        config,
      });
    }
    const script = document.createElement('script');
    script.setAttribute('id', `ga-${identifier}`);
    script.innerHTML = `(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
    (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');`;
    document.head.appendChild(script);

    ga('create', `${identifier}`, 'auto');
    ga('send', 'event', 'components', 'loaded');
  } else {
    if (config.debug) {
      console.log('lib.js:DO NOT include ga script because it already exists',
        {
          identifier,
          config,
          details: window.ga,
        });
    }
  }
};

const includeGTM = (identifier, config) => {
  if (!window.dataLayer || window.dataLayer === undefined) {
    if (config.debug) {
      console.log('lib.js:include gtm script', {
        identifier,
        config,
      });
    }
    const script = document.createElement('script');
    script.setAttribute('id', `gtm-${identifier}`);
    script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${identifier}');`;
    document.head.appendChild(script);
  } else {
    if (config.debug) {
      console.log('lib.js:DO NOT include gtm script because it already exists',
        {
          identifier,
          config,
          details: window.dataLayer,
        });
    }
  }
};

const includeFBQ = (identifier, config) => {
  if (!window.fbq || window.fbq === undefined) {
    if (config.debug) {
      console.log('lib.js:include fbq script', {
        identifier,
        config,
      });
    }
    const script = document.createElement('script');
    script.setAttribute('id', `fbq-${identifier}`);
    script.innerHTML = `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
    n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
    document,'script','https://connect.facebook.net/en_US/fbevents.js');`;
    document.head.appendChild(script);
    fbq('init', config.analytics.fbq);
  } else {
    if (config.debug) {
      console.log('lib.js:DO NOT include fbq script because it already exists',
        {
          identifier,
          config,
          details: window.fbq,
        });
    }
  }
};

const appendScript = (src) => {
  script.setAttribute('src', src);
  script.async = true;
  document.head.appendChild(script);
};

const info = () => {
  console.info({
    version,
    config: getConfig(),
    components: Object.keys(components),
  });
};

const fns = {
  configure,
  loadComponent,
  info,
};

const koc = (fn, ...args) => {
  if (typeof fns[fn] === 'function') {
    return fns[fn](...args);
  }
  throw new Error(`No command exists for '${fn}'`);
};

const { isArray } = Array;
const { kocQueue } = window;

if (isArray(kocQueue)) {
  kocQueue.forEach((args) => {
    koc(...args);
  });
}
window.koc = koc;
